<template>

  <div class=" container ">
    <div class=" ">
      <div class="row align-items-center justify-content-center my-4 ">
        <div class="col-5 col-sm-4 col-md-3 col-lg-2">
          <img src="@/assets/images/logo-ecart.png" alt="" class=" img-fluid px-4">
        </div>
      </div>

      <div class="card mb-4 card-ecart">
        <div class="card-header ">
          <div class=" row justify-content-between align-items-center ">
            <div class="col-auto mx-auto">
              <h5 class=" text-center " v-text=" $t('integration.title') "> </h5>
            </div> 
            <!-- <div class="col-auto ml-auto mt-2 d-sm-none">
              <router-link to="/logout" class=" btn btn-outline-light btn-sm  " v-text=" $t('general.logout') ">
              </router-link>
            </div>
            <div class="col-auto ml-auto d-none d-sm-block ">
              <router-link to="/logout" class=" btn btn-outline-light btn-sm  " v-text=" $t('general.logout') ">
              </router-link>
            </div> -->
          </div>

        </div>
        <div class="card-body">
          <div class="u-pearls-xs row justify-content-between d-none  "
            :class=" ( stepNum > 0 ) ? ' d-md-flex ': 'd-none' ">
            <div class="u-pearl col " :class=" {  'done': stepNum > index +1 , 'current': stepNum == index +1 }  "
              v-for="  (step,index ) in steps " :key=" index ">
              <span class="u-pearl-number" v-text=" index+1 "></span>
              <span class="u-pearl-title" v-text=" $t('integration.steps.'+ index ) "> </span>

            </div>
          </div>

          <div class="u-pearls-xs row justify-content-between " :class=" ( stepNum > 0 ) ? ' d-md-none ': 'd-none' ">
            <template v-for="  (step,index ) in steps ">

              <div class="u-pearl col done " :key=" index + 'last' "
                v-if=" stepNum == steps.length -1 && index +1 ==  stepNum ">
                <span class="u-pearl-number" v-text=" '...' "></span>
                <span class="u-pearl-title" v-text="  '' "> </span>
              </div>

              <div class="u-pearl col " :class=" {  'done': stepNum > index +1 , 'current': stepNum == index +1 } "
                :key=" index " v-if="   stepNum == index +1 || index +1  == steps.length  ">
                <span class="u-pearl-number" v-text=" index+1 "></span>
                <span class="u-pearl-title" v-text=" step "> </span>
              </div>

              <div class="u-pearl col " :key=" index + 'last' "
                v-if="   stepNum == index +1 && index +1  < steps.length -1 ">
                <span class="u-pearl-number" v-text=" '...' "></span>
                <span class="u-pearl-title" v-text="  '' "> </span>
              </div>
            </template>
          </div>
        </div>
        <div class="card-footer">
          <div class="row justify-content-between align-items-center">
            <div class="col-2 d-none d-md-block"></div>
            <div class="col col-md-auto mx-md-auto">
              <p class="m-0">
                <span v-text="$t('general.form.email') + ': '"> </span>
                <strong v-text="user.email"> </strong>
              </p>
            </div>
            <div class="col-auto col-md-2">
              <p class="text-right">
                <router-link to="/logout" class=" btn btn-outline-light btn-sm  " v-text=" $t('general.logout') ">
                </router-link>
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
  } from "vuex";
  export default {
    data() {
      return {
        steps: this.$t('integration.steps'),
      }
    },
    computed: {
      ...mapState({
        stepNum: state => state.menu.stepNum,
        user: state => state.session.user,
      }),
    },

  }
</script>

<style>

</style>