<template>
  <div class="h-100">
    <NewHeader v-if=" stepNum == 0 " />
    <DashboardSteps v-show=" stepNum > 0" />

    <div class="row">
      <div class="col-4 col-lg-3 col-xl-2 bg-black pr-0 d-none d-md-block " v-if="stepNum == 0">
        <NewMenu />
      </div>
      <div :class=" (stepNum == 0) ? 'col-12 col-md-8 col-lg-9 col-xl-10 pl-md-0' : ' col-12' ">
        <div :class=" (stepNum == 0) ? 'px-2 px-md-4 mt-1 pt-md-4 mb-4  mb-md-0' : 'container' ">
          <transition name="fadeIn" enter-active-class="animated fadeIn ">
            <router-view></router-view>
          </transition>
          <DashboardFooter class="d-none d-md-block" />
        </div>
      </div>
    </div>

    <DownloadApp v-if="stepNum == 0" />
    <!-- <MobileMenu class="d-md-none" /> -->
  </div>
</template>

<script>
  import DashboardFooter from './components/index-footer';
  import DashboardSteps from './components/index-steps';
  import MobileMenu from './components/index-mobile-menu';
  import NewHeader from './components/new-header';
  import NewMenu from './components/new-menu-left';
  import DownloadApp from '@/components/DownloadApp';

  import {
    mapState,
    mapMutations,
    mapActions,
  } from "vuex";

  export default {
    name: 'dashboardIndex',
    components: {
      DashboardFooter,
      DashboardSteps,
      NewHeader,
      NewMenu,
      DownloadApp,
      MobileMenu,
    },
    data() {
      return {
        hideMenu: false,
      }
    },
    computed: {
      ...mapState('EcartNotifications', ['notifications', 'showNotifications']),
      ...mapState({
        ordersPendingCount: state => state.ordersPendingCount,
        menuList: state => state.menuList,
        user: state => state.session.user,
        stepNum: state => state.menu.stepNum,
        syncStores: state => state.syncStore.syncStoresList,
      }),
    },
    watch: {
      showNotifications() {
        if (this.showNotifications) {
          document.getElementsByTagName('body')[0].classList.add('modal-open');
        } else {
          document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
      },
      $route(to, from) {
        this.fnGetUserInfo()
        this.changeNotifications(false);
        if (this.$route.query.integration) {
          this.setIntegration(true);
        } else {
          this.setIntegration(false);
        }
      }
    },
    methods: {
      ...mapMutations(['setIntegration']),
      ...mapMutations('EcartNotifications', ['changeNotifications']),
      ...mapActions('session', ['fnGetUserInfo']),
    },
    async beforeMounted() {},
    mounted() {}
  }
</script>

<style lang="scss" scoped>
</style>