<template>
    <div class="px-4 ecart-menu-mobile" v-if=" stepNum == 0 ">
        <div class="d-flex w-100 justify-content-between align-items-center">
            <router-link to="/dashboard/dashboard" class="btn btn-xl btn-outline-success circle-50 p-0 " exact>
                <i class="fa fa-tachometer-alt"></i>
            </router-link>
            <!-- <button class="btn btn-outline-primary border-1 circle-50 f-40">
                +
            </button> -->
            <router-link to="/dashboard/orders" class="btn btn-xl btn-outline-success circle-50 p-0 " exact>
                <i class="fa  fa-file-alt"></i>
            </router-link>
            <router-link to="/dashboard/account" class="btn btn-xl btn-outline-success circle-50 p-0 " exact>
                <i class="far fa-user-circle"></i>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    export default {
        computed: {
            ...mapState({
                stepNum: state => state.menu.stepNum,
            }),
        }
    }
</script>

<style lang="scss">
    .ecart-menu-mobile {
        display: flex;
        height: 70px;
        width: 100%;
        background-color: white;
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 100;
        box-shadow: 0 0 0.75rem rgba($color: #000000, $alpha: .4);
    }
</style>