<template>
    <div class="menu-container">
        <div class="pl-3 mb-5 router-links">
            <div class="" v-for=" (item,index ) in menuList " :key=" index ">
                <router-link :to="item.path" class=" f-w-500 text-white w-100 d-block py-2 my-2 px-3 f-16" exact-path
                    v-if="!hideMenu">
                    <div class="row">
                        <div class="col-3 pr-0">
                            <p class="m-0 text-center">
                                <i class=" fa f-20" :class=" 'fa-' + item.icon "></i>
                            </p>
                        </div>
                        <div class="col-9">
                            <p class="m-0">
                                <span class="text-left mr-2 " v-text=" $t('dashboard.menu.' + item.title ) "></span>
                                <span class="badge badge-pill badge-warning"
                                    v-if="item.title == 'orders' && ordersPendingCount ">
                                    <strong class="f-w-600"
                                        v-text="ordersPendingCount > 9 ? '9+' : ordersPendingCount"></strong>
                                </span>
                            </p>
                        </div>
                    </div>

                </router-link>
            </div>
        </div>
        <div class="border-top d-flex flex-column  justify-content-center pl-5 py-5 ">
            <p v-for=" (link,index) in links " :key=" index ">
                <router-link :to="link.link" v-text=" link.title " :target=' link.target '
                    v-if=" link.target == '_self'  "> </router-link>
                <a :href="link.link" :target=' link.target ' v-text=" link.title " v-else> </a>
            </p>
        </div>
    </div>
</template>

<script>
    import {
        mapState,
    } from "vuex";

    export default {
        data() {
            return {
                hideMenu: false,

                links: [{
                        title: this.$t('components.footer.links.policy'),
                        link: "/dashboard/privacy-policy",
                        target: '_self'
                    },
                    {
                        title: this.$t('components.footer.links.terms'),
                        link: "/dashboard/terms-&-conditions",
                        target: '_self'
                    },
                    {
                        title: this.$t('components.footer.links.faqs'),
                        link: "/dashboard/faqs",
                        target: '_self'
                    },
                    {
                        title: this.$t('components.footer.links.returns'),
                        link: "/dashboard/returns-and-cancellations",
                        target: '_self'
                    },
                    {
                        title: this.$t('components.footer.links.ecart'),
                        link: "https://ecart.com",
                        target: '_blank'
                    },
                    {
                        title: this.$t('components.footer.links.promotions'),
                        link: "/dashboard/promotions",
                        target: '_self'
                    },
                ],

            }
        },
        computed: {
            ...mapState('EcartNotifications', ['notifications', 'showNotifications']),
            ...mapState({
                ordersPendingCount: state => state.ordersPendingCount,
                menuList: state => state.menuList,
                user: state => state.session.user,
                stepNum: state => state.menu.stepNum,
                syncStores: state => state.syncStore.syncStoresList,
            }),
        },
    }
</script>

<style lang="scss" scoped>
    .menu-container {
        position: sticky;
        width: 100%;
        height: calc(100vh - 85px);
        top: 85px;
        left: 0px;
        background-color: black;
        overflow-y: scroll;

        .router-links {
            a {
                background-color: black;
                transition-delay: 0.25s !important;
                transition-property: all !important;
            }

            .router-link-exact-active {

                &.active {
                    background-color: #81b23a;
                    border-radius: 20px 0 0 20px;

                }
            }
        }

    }
</style>