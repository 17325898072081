<template>
    <div class="ecart-download" v-if="showContent && stepNum == null">
        <div class="row justify-content-end h-100">
            <div class="col-12 col-md-8 col-lg-9 col-xl-10 h-100">
                <div class="row justify-content-center h-100">
                    <div
                        class="col-10 col-sm-8 col-md-8 col-lg-6 col-xl-auto px-lg-5 h-100 download-content rounded-pill d-flex flex-column justify-content-between py-3">
                        <h5 class="text-center">
                            <!-- <strong v-html="$t('messages.ecartSellerApp' )"> -->
                            <strong v-html="$tc('messages.ecartSellerApp', 1 , {color: 'text-white' })">
                            </strong>
                        </h5>
                        <div class="row justify-content-center align-items-center text-center">
                            <div class=" col-auto ">
                                <a href="https://play.google.com/store/apps/details?id=com.ecart.seller"
                                    target="_blank">
                                    <img src="@/assets/images/landing/icon_playstore.svg" class="img-100"  alt="">
                                </a>
                            </div>
                            <div class=" col-auto ">
                                <a href="https://apps.apple.com/mx/app/ecart-seller/id1559326106" target="_blank">
                                    <img src="@/assets/images/landing/icon_appstore.svg" class="img-100" alt="">
                                </a>
                            </div>
                        </div>
                        <button class="btn btn-primary btn-close " @click="fnCloseContent">
                            <i class="fa fa-times-circle"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex';
    export default {
        data() {
            return {
                showContent: false,
            }
        },
        computed: {
            ...mapState(['menu', 'stepNum']),
        },
        methods: {
            fnCloseContent() {
                this.showContent = false;
                localStorage.setItem('downloadApp', 'true');
            }
        },
        mounted() {
            if (!localStorage.getItem('downloadApp') && !localStorage.getItem('device')) {
                this.showContent = true;
            }
        }
    }
</script>

<style lang="scss">
    .ecart-download {
        position: fixed;
        bottom: 0.5rem;
        left: 0;
        z-index: 200;
        width: 100%;
        height: 110px;

        .download-content {
            color: white;
            background-color: #81b23a;

            .btn-close {
                top: 0;
                right: 0;
                width: 30px;
                height: 30px;
                padding: 0px;
                border-radius: 50%;
                position: absolute;
            }

        }
    }
</style>