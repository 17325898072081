<template>
  <div>
    <footer class="footer">

      <div class="container-fluid">
        <div class="row text-center text-md-left">
          <div class="col-12 col-lg">
            <p class=" m-0">
              <strong>
                © <span v-text=" new Date().getFullYear() "></span> Ecart. All Rights Reserved
              </strong>
              <br>
              <span v-for=" (link,index) in links " :key=" index ">
                <router-link :to="link.link" v-text=" link.title " :target=' link.target '
                  v-if=" link.target == '_self'  "> </router-link>
                <a :href="link.link" :target=' link.target ' v-text=" link.title " v-else> </a>
                <strong v-text=" ( (index < links.length -1 ) ? ' - ':'' )  "></strong>
              </span>
            </p>
          </div>
        </div>
      </div>

    </footer>
  </div>
</template>

<script>
  export default {
    name: 'footerPage',
    data() {
      return {
        links: [{
            title: this.$t('components.footer.links.policy'),
            link: "/dashboard/privacy-policy",
            target: '_self'
          },
          {
            title: this.$t('components.footer.links.terms'),
            link: "/dashboard/terms-&-conditions",
            target: '_self'
          },
          {
            title: this.$t('components.footer.links.faqs'),
            link: "/dashboard/faqs",
            target: '_self'
          },
          {
            title: this.$t('components.footer.links.returns'),
            link: "/dashboard/returns-and-cancellations",
            target: '_self'
          },
          {
            title: this.$t('components.footer.links.ecart'),
            link: "https://ecart.com",
            target: '_blank'
          },
          {
            title: this.$t('components.footer.links.promotions'),
            link: "/dashboard/promotions",
            target: '_self'
          },
        ],
      }
    }
  }
</script>